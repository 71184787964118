<template>
  <div style="background: rgb(157,106,41); height: 100vh; background-size: cover; padding-bottom: 15rem; background-attachment: fixed; overflow-y: scroll;">
     <div class="form-group pt-4 d-flex justify-content-between px-2" style="width: 100%">
        <div >
          <h2 class="pl-2" style="color: white;">{{ $route.query.provider }}</h2>
        </div>
          <div>
              <input v-model="name" type="text" class="game-search" :placeholder="$t('home.search_game')">
          </div>
      </div>
        <div class="p-0 game-container px-1">
          <div
              class="p-0 game-card"
              v-for="(game, index) in newGames.length > 0 ? newGames : games"
              :key="index"
              @click="($route.query.provider === 'PGSoft' || $route.query.provider === 'FaChai') ? showAlertBox(game) : gameInit(game)"
          >
            <div class="pb-1" style="border: 1px solid #56381E; border-radius: 10px;">
              <img
                    :src="imageLinkGenerate(game.gameID)"
                    alt=""
                    class="pl-1 pt-1 pr-1"
                    style="width: 100%; height: 100%; border-radius: 10px; min-height: 100px;"
                />
            </div>
            <div class="d-flex flex-column p-0">
                <span class="pl-1 pt-2 text-uppercase" style="font-size: 0.8rem; color: black; font-weight: 550; text-overflow: ellipsis; overflow: hidden; width: 9rem; white-space: nowrap; ">{{game.gameName}}</span>
                <span class="pl-1 pb-2" style="color: #56381E; font-size: 0.8rem; font-weight: 550; text-overflow: ellipsis; overflow: hidden; width: 9rem; white-space: nowrap;">{{ $route.query.provider }}</span>
            </div>
          </div>
      </div>
      <div class="col-4">
          <div
              class="modal fade"
              id="limit"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modal-form"
              @click="hideLimit()"
          >
              <div
                  class="modal-dialog modal- modal-dialog-centered"
                  role="document"
              >
                  <div class="modal-content">
                      <div class="modal-body my-5 mx-3 text-center">
                        <span class="text-danger text-lg">
                          <b>အထူးသတိပြုရန်။</b> <br><br>
                        </span>
                      <span>{{limitText}}</span>
                      <br><br> <span class="text-dark text-sm">
                          <button class="btn btn-danger" @click="gameInit(desiredGame)">
                          {{
                              $store.state.language === "en"
                                  ? "Play The Game"
                                  : "ဂိမ်းဆော့မည်။"
                          }}
                          </button>
                      </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    
  </div>
</template>
<script>
// import { DropdownMenu, DropdownItem } from 'vant';
import { mapActions } from 'vuex';

export default {
//   components: { DropdownMenu, DropdownItem },

data() {
  return {
    games: [],
    rows: 0,
    provider: 'Pragmatic',
    name: null,
    newGames: [],
    limitText: '',
    desiredGame: null,
  };
},
methods: {
  ...mapActions(['fetchUser']),
  hideLimit() {
    $('#limit').modal('hide');
  },
  showAlertBox(game) {
    if(this.$route.query.provider === 'PGSoft') this.limitText = 'PG Soft တွင် 1K unit သည် 1,000 ကျပ်နှင့်ညီမျပါသည်။';
    else if(this.$route.query.provider === 'FaChai') this.limitText = 'FaChai Provider တွင် 1 unit သည် 100 ကျပ်နှင့်ညီမျပါသည်။';
    // else this.limitText = '5G Provider တွင် 1K unit သည် 100 ကျပ်နှင့်ညီမျပါသည်။';
    $('#limit').modal('show');
    this.desiredGame = game;
  },
  imageLinkGenerate(gameId) {
    if (this.$route.query.provider === 'Jili') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/jili/GAMEID_${gameId}_EN.png`;
    } if (this.$route.query.provider === 'Spade') {
      return `https://merchantapi.silverkirin88.com/thumbnail/en_US/${gameId}.jpg`;
    } if (this.$route.query.provider === 'Fastspin') {
      return `http://api-egame-staging.fsuat.com/thumbnail/en_US/${gameId}.jpg`;
    } if (this.$route.query.provider === 'Playstar') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/playstar/${gameId}.png`;
    } if (this.$route.query.provider === 'FaChai') {
      return `https://agent-icon.fcg1688.net/icon/${gameId}_200x200_en.png`;
    } if (this.$route.query.provider === 'PGSoft') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/pgsoft/pgsoft_${gameId}.png`;
    } if (this.$route.query.provider === 'JOKER') {
      return `https://img.qiangmingbao.net/gameimages/landscape/${gameId}.png`;
    } if (this.$route.query.provider === '5G') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/5g/${gameId}.png`;
    } if (this.$route.query.provider === 'KA') {
      return `https://rmpiconcdn.kaga88.com/kaga/gameIcon?game=${gameId}&lang=en&type=circular_framed`;
    } if (this.$route.query.provider === 'AceWin') {
      return `https://yy24gld.sgp1.cdn.digitaloceanspaces.com/acewin/${gameId}.png`;
    } if (this.$route.query.provider === 'JDB') {
        return `https://dlntdk2it99.anhuidingyue.com/jdb-assetsv3/games/${gameId}/${gameId}_en.png`;
      }

    // return `https://api-2133.ppgames.net/game_pic/rec/325/${gameId}.png`;
    return `https://space-tech.sgp1.cdn.digitaloceanspaces.com/pp/${gameId}.png`;
  },
  detectMob() {
    let check = false;
    /* eslint-disable */
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        )
                  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4),
                  )
      ) { check = true; }
    }(navigator.userAgent || navigator.vendor || window.opera));
    return check;
  },

  async fetchGames() {
    try {
      // this.$store.commit('setLoading', true);
      const res = await axios.get('/games', {
        params: {
          provider: this.$route.query.provider,
        },
      });
      this.games = res.data.data;

      this.games = this.games.filter((game) => game.gameTypeID.includes('vs'));

      if(this.$route.query.provider === 'Pragmatic'){
        let games = this.games;
        // Cut the last 10 elements
        let lastTenGames = games.slice(-10);

        // Get the remaining elements (excluding the last 10)
        let remainingGames = slice(0, -10);

        // Merge the last 10 games at the beginning with the remaining games
        let reorderedGames = lastTenGames.concat(remainingGames);

        // Use the reordered array (update your state if using Vue)
        this.games = reorderedGames;
      }
      // this.$store.commit('setLoading', false);
    } catch (error) {
      // this.$store.commit('setLoading', false);
      console.log(error);
    }
  },

  async gameInit(game) {
    try {
      this.$store.commit('setLoading', true);
      const authUser = await this.fetchUser();
      if (!authUser) {
        this.$router.push('/auth/login');
      }
      
      const res = await axios.get('/games/url', {
        params: {
          gameID: game.gameID,
          provider: this.$route.query.provider
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
        
        if(this.$route.query.provider === 'PGSoft'){
          const newPage = window.open('', '_self');

          // Write HTML content into the new window
          if (newPage) {
            newPage.document.open();
            newPage.document.write(res.data.data.gameUrl);
            newPage.document.close();
          }
        } else {
          window.open(res.data.data.gameUrl, "_self");
        }

      // const newWindow = window.open(res.data.data.gameUrl, "_self");
      // if (newWindow) {
      //   setTimeout(() => {
      //     this.$store.commit('setLoading', false);
      //   }, 1000);
      // } else {
      //   this.$store.commit('setLoading', true);
      // }
      
      this.$store.commit('setLoading', false);
    } catch (error) {
      this.$store.commit('setLoading', false);
    }
  },
},
watch: {
  provider() {
      this.fetchGames();
  //   this.newGames = this.games.filter((game) => game.provider.includes(this.provider));
  },
  name() {
    this.newGames = this.games.filter((game) => game.gameName.toLowerCase().includes(this.name.toLowerCase()));
  },
},
async mounted() {
  this.$store.commit('setLoading', true);
  await this.fetchGames();
  this.$store.commit('setLoading', false);
},
};
</script>
<style scoped>
.game-container {
  display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(5, 1fr);
grid-column-gap: 10px;
grid-row-gap: 10px;
}
.provider-dropdown {
  width: 200px;
  height: 40px;
}

.game-search {
  width: 230px !important;
  /* height: 40px; */
  border: 1px solid #56381E;
  background: transparent;
  color: black;
  border-radius: 5px;
  text-align: center;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.game-search::placeholder {
  color: #56381E;
}

/* .game-search:active, .game-search:focus {
  border: 1px solid #000 !important;
} */

.game-card {
  /* box-shadow: rgba(0, 0, 255, 0.25) 0px 2px 5px -1px,
      rgba(232, 155, 158, 0.3) 0px 1px 3px -1px !important; */
  flex: 1 calc(33.333% - 10px);

  background: transparent;
  border-radius: 10px !important;
  /* border: 1px solid #323542; */
  margin-bottom: 15px !important;
  height: auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
