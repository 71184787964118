<template>
    <div class="container-fluid d-flex flex-wrap main-wrapper" style="border: none !important; position: absolute; background: rgb(157,106,41); height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-12 p-0 mb-5 bg-transparent" style="height: 150vh;">
            <div class="bg-transparent">
                <div class="bg-transparent pl-2 pt-3">
                    <div class="align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0" style="color: #fff;">
                                {{$t('deposit.deposit_form')}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="error text-center py-2" v-if="error">
                    <span class="mx-auto">
                      {{error}}
                    </span>
                    
                  </div>

                <div class="card-body bg-transparent">
                    <form>
                        <!-- <h6 class="heading-small" style="color: #fff !important;">
                            {{
                                $store.state.language === "en"
                                    ? "Account information"
                                    : "အကောင့်ဆိုင်ရာ အချက်အလက်များ"
                            }}
                        </h6>
                        <span class="heading-small">{{
                                    $store.state.language === "en"
                                        ? "User's Balance"
                                        : "လက်ကျန်ငွေ"
                                }} - </span>
                        <span>
                          <b class="text-dark">{{ Number($store.state.amount).toLocaleString() }}</b>
                          {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }}
                        </span> -->
                        <div class="mt-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-username"
                                            style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;"
                                            >{{$t('deposit.choose_payment_type')}}</label
                                        >
                                        <v-select :options="accounts" label="account_name" v-model="selected_account.account_id" :reduce="(account) => account.id">
                                          <template v-slot:option="option">
                                              <img :src="option.link" style="width: 25px;"/>
                                              {{ option.account_name }}
                                          </template>
                                        </v-select>
                                        <span class="text-dark">{{
                                            errors.account
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 p-0 d-flex flex-wrap"
                                v-if="account.length !== 0"
                            >
                                <div class="col-12 py-3">
                                    <span style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;">{{ account[0].name }}</span>
                                    <nav class="side-nav my-2" style="border-radius: 10px; background: linear-gradient(180deg, rgb(255,242,135) 0%, rgb(255,242,135) 100%);">
                                      <div class="text-center py-2 pt-3">
                                        <!-- <img @click="$router.push('/banking')" style="position: absolute; height: 2rem; right: 2.5rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dc500777-e8e3-4267-ace1-cdcf5a215600/public" alt=""> -->
                                        <!-- <h3 class="text-white">{{ account[0].name }}</h3> -->
                                        <span class="text-dark" style="font-size: 1rem; font-weight: 400 !important;">{{ account[0].account_name }}</span>
                                        <h3 class="text-dark py-2" id="textToCopy">{{ account[0].number }}</h3>
                                        <div class="mx-auto mb-2">
                                          <span class="text-white badge badge-dark" @click="copyText">Copy</span>
                                        </div>
                                      </div>
                                    </nav>
                                    
                                    <span class="text-sm" style="color: #E4E2E2">{{account[0].note}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <hr class="my-4" /> -->
                        <div class="my-3">
                            <div class="row">
                                <!-- <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="form-control-label" style="color: #fff !important;">{{$t('deposit.screenshot')}}</label>

                                        <div class="custom-file d-flex">
                                            <van-uploader
                                                class="mx-auto"
                                                v-model="imageUploader"
                                                :max-count="1"
                                            />
                                        </div>
                                        <span class="text-dark">{{
                                            errors.image
                                        }}</span>
                                    </div>
                                </div> -->
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;"
                                            >{{$t('deposit.amount')}} </label
                                        >
                                        <span class="text-dark ml-2" style="font-size: 0.9rem; font-weight: 500 !important;">{{ $t('deposit.minimum_deposit')}}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control custom-input"
                                            v-model="depositData.amount"
                                        />
                                        <span class="text-dark">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="form-group">
                                <label class="form-control-label text-white" style=" font-size: 1rem; font-weight: 400 !important;">{{
                                    $store.state.language === "en"
                                        ? "Last Transaction Id 6"
                                        : "လုပ်ဆောင်မှုအမှတ်စဉ် (နောက်ဆုံး 6 လုံး)"
                                }}</label>
                                <input
                                    type="number"
                                    id="input-email"
                                    class="form-control custom-input"
                                    v-model="depositData.remark"
                                />
                                <span class="text-dark">{{
                                    errors.remark
                                }}</span>

                            </div>
                            <div class="form-group pt-3">
                                <button
                                    type="button"
                                    class="btn btn-primary custom-btn"
                                    @click="sendDepositData()"
                                >
                                    <div v-if="!isConfirm">
                                        {{ $t('deposit.submit')}}
                                    </div>

                                    <Loading
                                        color="#000000"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      selected_account: {},
      accounts: [],
      account: [],
      imageUploader: [],
      depositData: {},
      errors: {},
      isConfirm: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    copyText() {
      // Get the text to copy
      const textToCopy = document.getElementById('textToCopy').innerText;

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();

      // Execute the 'copy' command
      document.execCommand('copy');

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      alert('You have successfully copied!');
    },
    async fetchAccounts() {
      try {
        const res = await axios.get('/accounts', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.accounts = res.data.data;

        // Group objects by name
        // const groupedByName = this.accounts.reduce((acc, obj) => {
        //     acc[obj.name] = acc[obj.name] || [];
        //     acc[obj.name].push(obj);
        //     return acc;
        // }, {});

        // // Get a random object from each group
        // const getRandomObjectFromGroup = (group) => {
        //     const randomIndex = Math.floor(Math.random() * group.length);
        //     return group[randomIndex];
        // };

        // // Collect the random objects into a single array
        // this.accounts = Object.keys(groupedByName).map(name => getRandomObjectFromGroup(groupedByName[name]));

      } catch (error) {
        console.log(error);
      }
    },
    async sendDepositData() {
      try {
        const authUser = await this.fetchUser();
        this.errors = {};
        if (!this.selected_account.account_id) {
          this.errors.account = this.$store.state.language === 'en'
            ? 'Please pick account type'
            : 'အကောင့်အမျိုးအစား ရွေးချယ်ပေးပါ။';
          return false;
        }
        // if (!this.imageUploader.length > 0) {
        //   this.errors.image = this.$store.state.language === 'en'
        //     ? 'Please insert screenshot photo'
        //     : 'ငွေပေးချေမူမှတ်တမ်း (screenshot) ထည့်သွင်းပါ။';
        //   return false;
        // }
        if (!this.depositData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
          return false;
        }
        if (!this.depositData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
          return false;
        }
        if (this.depositData.amount < 5000) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter at least 5000 MMK'
            : 'ငွေပမာဏအနည်းဆုံး 5000 ထည့်ပေးပါ။';
          return false;
        }
        if (!this.depositData.remark || this.depositData.remark.length < 6) {
          this.errors.remark = this.$store.state.language === 'en'
            ? 'Please enter last transaction id 6'
            : 'Please enter last transaction id 6';
          return false;
        }
        this.isConfirm = true;
        // if (this.imageUploader[0]) {
        //   this.depositData.transaction_image = this.imageUploader[0].content;
        // }
        this.depositData.account_id = this.account[0].id;
        this.depositData.date = this.currentTime;
        this.depositData.lang = localStorage.getItem("language");
        const res = await axios.post('/deposits', this.depositData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.imageUploader = [];
        this.depositData = {};
        this.selected_account.account_id = null;
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.isConfirm = false;
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။',
        );
        this.error = error.response.data
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  watch: {
    'selected_account.account_id': function () {
      this.account = this.accounts.filter(
        (account) => account.id === this.selected_account.account_id,
      );
    },
  },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    await this.fetchAccounts();
    const authUser = await this.fetchUser();
    // this.$store.commit('setLoading', false);
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if(authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
  },
};
</script>
<style scoped>
.error {
  margin-bottom: 13px;
  /* height: 40px; */
  display: flex;
  align-items: center;
  color: white;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0px 15px;
  background: transparent;
  /* font-weight: bold; */
}
.custom-btn {
  background: white !important;
  color: black;
  width: 100% !important;
  border-radius: 25px;
  height: 40px !important;
}
.custom-input, .custom-input:active, .custom-input:focus {
    background: transparent !important;
    color: black !important;
    border-radius: 30px;
    border: 1px solid #56381E !important;
    text-align: center;
    box-shadow: none !important;
    /* height: 50px; */
    font-size: 1.3rem;
    font-weight: 300;
}
.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 10px;
    /* color: white; */
    background: linear-gradient(0deg, rgba(255, 38, 104, .81) 0%, rgba(255, 72, 128, .81) 34%, rgba(255, 97, 146, .81) 100%);
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
