<template>
    <div>
      <iframe name="iframe1" :srcdoc="isHttp ? null : $store.state.iframeURL" :src="isHttp ? $store.state.iframeURL : null" class="iframe-style bg-dark" :class="isMobile ? 'iframe-sm' : 'iframe-lg'"></iframe>
      <div style="z-index: 1000000; bottom: 0 !important; width: 100%; height: 3vh;" :class="isMobile ? 'iframe-sm' : 'iframe-lg'">
        <van-dropdown-menu direction="up">
          <van-dropdown-item ref="item">
            <div style="padding: 5px 30px;" class="d-flex justify-content-between">
              <van-button color="linear-gradient(to right, #1e1e1e, #000000)" block round @click="$router.push('/home')">
                  
                  <span class="my-auto mt-2">နောက်ပြန်သွားမည်။</span>
              </van-button>
              <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block round @click="$router.push('/deposit')">
                
                <span class="my-auto pt-1">ငွေဖြည့်ရန်။</span>
              </van-button>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>
</template>
<script>

export default {
  data(){
    return {
      value1: 'Pragmatic',
      option1: [
        { text: 'Deposit', value: 'Deposit' },
      ],
      isMobile: false
    }
  },
  async mounted() {
    if (!this.$store.state.iframeURL) {
      this.$router.push('/home');
    }
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  },
  computed: {
    isHttp() {
      return this.$store.state.iframeURL.startsWith('http');
    }
  }
};
</script>
<style scoped>
.iframe-style {
    width: 100%; 
    height: 97vh; 
    top: 0 !important;
    border: none;
    z-index: 1000000;
}
.iframe-lg {
  position: absolute;
}
.iframe-sm {
  position: fixed;
}
</style>
