<template>
    <div class="d-flex flex-wrap mt-3">
        <div class="w-100 mb-3" v-if="!isLive">
            <Sticky>
                <NoticeBar
                    class="col-lg-12 col-12 mx-auto notice-bar"
                    scrollable
                    :text="noticeText"
                    v-if="noticeText"
                />
                </Sticky>
        </div>

        <div class="col-lg-7 col-12 mx-auto d-flex my-0" style="padding-left: 10px; padding-right: 10px;">
            <div class="mx-0 card d-flex justify-content-between col-12 flex-row py-2">
                <div class="col-4 d-flex flex-column p-0" style="cursor: pointer;" @click=" $router.push('/betslip-history')">
                    <img
                        src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/5ecc6097-2270-4ee3-bd1d-268da7f1f800/public"
                        alt=""
                        class="mx-auto"
                        style="width: 30px; height: auto"
                        />
                    <span class="text-center mt-1 text-dark">Records</span>
                </div>
                <div class="col-4 d-flex flex-column p-0" style="cursor: pointer;" @click=" $router.push('/user/2d/closed')">
                    <img
                        src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/2bafe2c0-40d5-474b-6f6b-66e53b58f800/public"
                        alt=""
                        class="mx-auto"
                        style="width: 30px; height: auto"
                        />
                    <span class="text-center mt-1 text-dark">Closed Days</span>
                </div>
                <div class="col-4 d-flex flex-column p-0" style="cursor: pointer;" @click=" $router.push('/user/2d/winner')">
                    <img
                        src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/e4c4e097-8457-4f9a-0363-9280d7439d00/public"
                        alt=""
                        class="mx-auto"
                        style="width: 30px; height: auto"
                        />
                    <span class="text-center mt-1 text-dark">Winners List</span>
                </div>
            </div>
        </div>
        
        <div class="col-xl-4 home-cards live-card my-0">
            <div class="card twod-threed-cards">
                <!-- <div class="card-header" v-if="isLive">
                    <div v-if="$store.state.language === 'en'">
                        2D live Result
                    </div>
                    <div v-else><h3>နှစ်လုံး တိုက်ရိုက်ရလဒ်</h3></div>
                    <div>
                        <b-spinner variant="secondary"></b-spinner>
                    </div>
                </div> -->
                <div class="card-body d-flex" v-if="isLive">
                    <div class="my-auto col-12">
                        <div class="text-center">
                            <span class="live-result">{{
                                    twoDdata.live
                                }}</span>
                        </div>
                        <div class="live-set-val-container d-flex col-12 p-0">
                            <div class="col-6 p-0 text-center">
                                <small>
                                    Set &nbsp;- &nbsp;
                                    <!-- <span class="live-number">{{
                                        twoDdata.live_set
                                    }}</span> -->
                                    <span class="live-number" v-html="highlightedNumber"></span>
                                </small>
                            </div>
                            <div class="col-6 p-0 text-center">
                                <small
                                    >Value &nbsp;- &nbsp;
                                    <span class="live-number" v-html="valueHighlightedNumber"></span>
                                    </small
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex" v-if="!isLive">
                    <div
                        class="
                            my-auto
                            mx-auto
                            not-live-text
                            d-flex
                            w-100
                            p-3
                            h-100
                        "
                    >
                        <div
                            class="w-50 d-flex"
                            style="
                                font-size: 80px;
                                color: red;
                                border-right: 1px solid grey;
                            "
                        >
                            <div
                                class="my-auto mx-auto"
                                v-if="twoDHistories.length > 0"
                            >
                                {{ lastNumber }}
                            </div>
                        </div>
                        <div class="d-flex w-50">
                            <div
                                class="my-auto mx-auto"
                                style="font-size: 15px"
                            >
                                <span style="color: #0095f8">
                                    {{
                                        $store.state.language === "en"
                                            ? "Last updated time"
                                            : "နောက်ဆုံး အပ်ပဒိတ်"
                                    }}</span
                                ><br />
                                <span>{{ lastUpdatedDraw }}</span>
                                <br />
                                <span>{{ lastUpdatedDate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 home-cards my-0">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3
                                class="mb-0"
                                v-if="$store.state.language === 'en'"
                            >
                                Recent 2d history
                            </h3>
                            <h3 class="mb-0" v-else>
                                နှစ်လုံးထွက်ပြီး ဂဏန်းများ
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <!-- Projects table -->
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr v-if="$store.state.language === 'en'">
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    Date
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    10:45 AM
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    12:00 AM
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    02:45 PM
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    04:30 PM
                                </th>
                            </tr>
                            <tr v-else>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    နေ့စွဲ
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    မနက် - 10:45
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    မနက် - 12:00
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    ညနေ - 02:45
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    ညနေ - 04:30
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(twodHistory, index) in twoDHistories"
                                :key="index"
                            >
                                <td class="text-center" scope="row">
                                    <h3>{{ twodHistory.draw_date }}</h3>
                                </td>
                                <td class="text-center">
                                    <h3>
                                        {{ twodHistory.mid_morning_result }}
                                    </h3>
                                </td>
                                <td class="text-center">
                                    <h3>
                                        {{ twodHistory.morning_result }}
                                    </h3>
                                </td>
                                <td class="text-center">
                                    <h3>
                                        {{ twodHistory.mid_evening_result }}
                                    </h3>
                                </td>
                                <td class="text-center">
                                    <h3>
                                        {{ twodHistory.evening_result }}
                                    </h3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        

        <!-- <div class="bet-container">
            <button class="btn btn-primary" @click="$router.push('/user/2d')">
                <h3 class="text-uppercase" style="color: #FFD73F">
                    {{
                        $store.state.language === "en"
                            ? "BET"
                            : "ထိုးမည်"
                    }}    
                </h3>
            </button>
        </div> -->
    </div>
</template>
<script>
import { NoticeBar, Sticky } from 'vant';

export default {
    components: {
        NoticeBar,
        Sticky
    },
  data() {
    return {
      twoDdata: {},
      isLive: false,
      twoDHistories: {},
      internetModern: [],
      interval: null,
      threedData: {},
      noticeText: this.$store.state.language === "en" ? '😔 Live is not available now. Please check back later. 😔' : '😔 Live ကြည့်လို့မရသေးပါ။ အချိန်နည်းနည်းကြာမှပြန်လာပေးပါ။ 😔',
    };
  },
  computed: {
    highlightedNumber() {
      const num = this.twoDdata.live_set;
      return num.slice(0, -1) + '<span class="text-danger">' + num.slice(-1) + '</span>';
    },
    valueHighlightedNumber() {
      const num = this.twoDdata.live_val;
      const index = num.indexOf('.') - 1; // Find the index of '9'
      if (index !== -1) {
        // Wrap the '9' in a span
        return num.slice(0, index) + '<span class="text-danger">' + num[index] + '</span>' + num.slice(index + 1);
      }
      return num; // Return the original number if '9' is not found
    },
    lastNumber() {
      return (
        this.twoDHistories[0]?.evening_result
                || this.twoDHistories[0]?.mid_evening_result || this.twoDHistories[0]?.morning_result || this.twoDHistories[0]?.mid_morning_result
      );
    },
    lastUpdatedDraw() {
      if (
        this.twoDHistories.length > 0
                && this.twoDHistories[0]?.evening_result
      ) return '4:30 PM';
      if (
        this.twoDHistories.length > 0
                && this.twoDHistories[0]?.mid_evening_result
      ) return '2:45 PM';
      if (
        this.twoDHistories.length > 0
                && this.twoDHistories[0]?.morning_result
      ) return '12:00 AM';
      return '10:45 AM';
    },
    lastUpdatedDate() {
      return this.twoDHistories[0]?.draw_date;
    },
  },
  methods: {
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDliveresult() {
        const apiUrl = 'https://luke.2dboss.com/api/luke/twod-result-live';

        // Make a GET request using the fetch function
        fetch(apiUrl)
            .then(response => {
            // Check if the request was successful (status code 2xx)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON response
            return response.json();
            })
            .then(data => {
            this.twoDdata = data.data
            console.log('this.twd', this.twoDdata)
            })
            .catch(error => {
            // Handle errors
            console.error('Error fetching data:', error);
            });
        // },
    },
    async fetchModernInternet() {
      try {
        const res = await axios.get('/modern-internet');
        this.internetModern = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
        if (this.isLive) {
            this.fetchTwoDliveresult();
            this.interval = setInterval(() => this.fetchTwoDliveresult(), 5000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    ready() {
    //   const channel = this.$pusher.subscribe('live-socket-channel');
    //   channel.bind('twod-live', async (data) => {
    //     this.twoDdata = data;
    //   });

      //   channel.bind('twod-isLive', async (data) => {
      //     this.isLive = data;
      //   });
    },

    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        this.twoDHistories = twoDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  destroyed() {
    // this.$pusher.unsubscribe('live-socket-channel');
    clearInterval(this.interval);
  },
  unmount() {
    // this.$pusher.unsubscribe('live-socket-channel');
    clearInterval(this.interval);
  },
  mounted() {
    this.fetchTwoDdata();
    this.fetchTwoThreeDHistories();
    // this.fetchModernInternet();
    // this.ready();
  },
};
</script>
<style scoped>
    
.notice-bar{
  background: rgb(228, 87, 87);
  color: black;
  font-size: 1.2rem;
  height: 30px;
}
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 70px;
    width: 100px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.main-image-wrapper img {
    width: 100%;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
@keyframes live-number-animation {
    from {
        color: white;
    }
    to {
        color: red;
    }
}

@media (max-width: 768px) {
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 30px;
        z-index: 1;
        font-size: 13px;
        color: #172b4c;
        background: rgba(255, 242, 0, 0.7);
    }
    .live-number {
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 0.1rem;
    }
    .live-result {
        font-weight: bolder;
        font-size: 100px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>
