<template>
    <div style="background-image: url('https://img.freepik.com/premium-photo/scene-showing-black-red-wallpaper_869640-735194.jpg?size=626&ext=jpg&ga=GA1.1.331063233.1723471334&semt=ais_hybrid'); min-height: 100vh; height: auto; background-size: cover;" class="pt-5">
            <div class="col-11 mx-auto card border-0 mb-0 auth-card mt-5" style="background: rgba(255, 255, 255, 0.25);">
                <img
                    class="mx-auto my-3"
                    src="/logo.jpg"
                    alt=""
                    @click="$router.push('/home')"
                    style="width: 20rem; height: auto"
                />
                
                <div class="card-body px-lg-7 py-lg-7">
                    <div class="text-center text-muted mb-4 text-white">
                      <!-- <h3 _ngcontent-dpt-c65="" class="text-white text-center text-sm">OTP ကုဒ်ကို SMS မှတစ်ဆင့်  သင့်ဖုန်းနံပါတ် <br><span class="text-danger text-lg">{{ $store.state.authUser.phone }}</span> သို့ ပေးပို့ပြီးပါပြီ။</h3> -->

                      
                      <div class="mb-4ds">
                        <span class="pb-4 font-weight-600">Please enter your phone number <br> to receive OTP Code.</span>
                      </div>

                      <div class="error text-white d-flex" v-if="error">
                            <span class="mx-auto">
                                {{ error }}
                            </span>
                        </div>
                        <div class="text-left mt-3">
                          <span class="text-sm text-white">Phone Number here : </span>
                        </div>
                        
                        <input
                          class="form-control pl-2 px-4 mt-1"
                          style="color: black; box-shadow: none; border-radius: 8px; border: none; font-size: 1.2rem;"
                          type="text"
                          v-model="phone"
                        />
                    </div>
                    <form role="form" class="mb-3">
                      <span class="text-sm text-white pb-3">New Password here : </span>
                      <input
                          class="form-control pl-2 px-4 mt-1"
                          style="color: black; box-shadow: none; border-radius: 8px; border: none; font-size: 1.2rem;"
                          type="text"
                          v-model="newPassword"
                        />
                    </form>
                    <form role="form">
                      <span class="text-sm text-white pb-3">OTP Code here : </span>
                      <input
                          class="form-control pl-2 px-4 mt-1"
                          style="color: black; box-shadow: none; border-radius: 8px; border: none; font-size: 1.2rem;"
                          type="number"
                          v-model="code"
                        />
                        <div
                            class="
                                text-center
                                mt-3
                                d-flex
                                justify-content-between
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-success my-4"
                                style="color: white !important;"
                                @click="verifyCode"
                            >
                                <div v-if="!isVerifying">
                                    {{
                                        $store.state.language === "en"
                                            ? "Confirm"
                                            : "အတည်ပြုမည်။"
                                    }}
                                </div>

                                <Loading
                                    color="#ffffff"
                                    v-if="isVerifying"
                                    size="24"
                                    class="px-4 py-0"
                                />
                            </button>
                            <van-count-down class="pt-2" v-if="$store.state.authUser.otp_resend_expired_at > 0" :time="$store.state.authUser.otp_resend_expired_at" @finish="finishCountdown">
                              <template #default="timeData">
                                <span class="block">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block">{{ timeData.seconds }}</span>
                              </template>
                            </van-count-down>

                            <button
                                type="button"
                                class="btn btn-dark my-4"
                                @click="resendCode"
                            >
                                <div v-if="!isResend">
                                    {{
                                        $store.state.language === "en"
                                            ? "Send Code"
                                            : "Code ပို့ပေးပါ။"
                                    }}
                                </div>
                                <Loading
                                    color="#ffffff"
                                    size="24"
                                    class="px-4 py-0"
                                    v-else
                                />
                            </button>
                        </div>
                        <!-- <div class="col-6">
                            <a @click="logout" class="new-account-text"
                                ><b>{{
                                    $store.state.language === "en"
                                        ? "Re-create account"
                                        : "အကောင့်ပြန်ဖွင့်မည်။"
                                }}</b></a
                            >
                        </div> -->
                    </form>




                </div>
            </div>
    </div>
</template>

<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      phone: "",
      code: "",
      error: false,
      errors: {},
      isVerifying: false,
      isResend: false,
      showKeyboard: true,
      newPassword: null
    };
  },
  methods: {
    finishCountdown() {
      console.log('success');
      this.fetchUser();
    },
    ...mapActions(['fetchUser']),
    logout() {
      try {
        this.$auth.logout({
          redirect: '/auth/register',
        });
      } catch (error) {
        console.log(error);
      }
    },
    async verifyCode() {
      this.isVerifying = true;
      this.error = false;

      var myanmarPhoneNumber = require('myanmar-phonenumber/myanmar-phonenumber.js');
      if (!this.phone) {
        this.error = this.$store.state.language === 'en'
          ? 'Required Phone Number'
          : 'Phone Number လိုအပ်ပါသည်။';
        this.isVerifying = false;
        return false;
      }
      if (this.phone && !myanmarPhoneNumber.isValidMMPhoneNumber(this.phone)){ // returns true if it meets Myanmar Phone Number conditions) {
        this.error = this.$store.state.language === 'en'
          ? 'Invalid phone number'
          : 'Invalid phone number';
        this.isVerifying = false;
        return false;
      }

      if (!this.newPassword) {
        this.error = this.$store.state.language === 'en'
          ? 'Required New Password'
          : 'New Password လိုအပ်ပါသည်။';
        this.isVerifying = false;
        return false;
      }
      if (this.newPassword && this.newPassword.length < 6) {
        this.error = this.$store.state.language === 'en'
          ? 'newPassword must be at least 6 characters'
          : 'newPassword သည် အနည်းဆုံး 6 လုံးဖြစ်ရမည်။';
        this.isVerifying = false;
        return false;
      }
      if (!this.code) {
        this.error = this.$store.state.language === 'en'
          ? 'Required Code'
          : 'Code လိုအပ်ပါသည်။';
        this.isVerifying = false;
        return false;
      }
      if (this.code && this.code.length < 6) {
        this.error = this.$store.state.language === 'en'
          ? 'Code must be 6 characters'
          : 'Code သည် 6 လုံးဖြစ်ရမည်။';
        this.isVerifying = false;
        return false;
      }
      try {
        const res = await axios.post('/auth/verify-forgot-code', {
          two_factor_code: this.code,
          phone: this.phone,
          password: this.newPassword
        });
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.$router.push('/auth/login');
        this.error = false;
        this.isVerifying = false;
      } catch (err) {
        this.isVerifying = false;

        if(err.response.data.error)
        this.error = err.response.data.error

        if(err.response.data.errors['two_factor_code'] && err.response.data.errors['two_factor_code'][0])
        this.error = err.response.data.errors['two_factor_code'][0]

        if(err.response.data.errors['phone'] && err.response.data.errors['phone'][0])
        this.error = err.response.data.errors['phone'][0]

        if(err.response.data.errors['password'] && err.response.data.errors['password'][0])
        this.error = err.response.data.errors['password'][0]
      }
      this.isVerifying = false;
      return true;
    },
    async resendCode() {
      var myanmarPhoneNumber = require('myanmar-phonenumber/myanmar-phonenumber.js');

      this.isResend = true;
      this.error = false;
      if (!this.phone) {
        this.error = this.$store.state.language === 'en'
          ? 'Required Phone Number'
          : 'Phone Number လိုအပ်ပါသည်။';
        this.isResend = false;
        return false;
      }
      if (this.phone && !myanmarPhoneNumber.isValidMMPhoneNumber(this.phone)){ // returns true if it meets Myanmar Phone Number conditions) {
        this.error = this.$store.state.language === 'en'
          ? 'Invalid phone number'
          : 'Invalid phone number';
        this.isResend = false;
        return false;
      }
      try {
        const res = await axios.get('/auth/send-code', {
          params: {
            phone: this.phone,
          },
        });

        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
      } catch (err) {
        this.error = err.response.data.error;
        this.isResend = false;
      }
      this.isResend = false;
      return true;
    },
  },
};
</script>

<style scoped>
/* Change placeholder text color for all input elements */
::placeholder {
            color: #2c2b2b; /* Light gray color */
        }

.showable-password {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1000;
}
.new-account-text {
    cursor: pointer;
}
.error {
    margin-bottom: 13px;
    margin-top: 13px;
    height: 30px;
    display: flex;
    align-items: center;
    color: white;
    border: 1px solid red;
    border-radius: 8px;
    padding: 0px 15px;
    background: #ff000014;
    font-weight: bold;
}
.auth-card {
    background: #f7fafc;
}



.colon {
    display: inline-block;
    margin: 0 4px;
    color: #1989fa;
  }
  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #1989fa;
    border-radius: 4px;
  }
</style>
