<template>
  <div
    class="d-flex flex-column main-wrapper"
    style="
      padding-left: 6px !important;
      padding-right: 6px !important;
      height: 100vh;
      overflow-y: scroll;
      background: rgb(157,106,41); background-size: cover; padding-bottom: 15rem; background-attachment: fixed;
    "
  >
    <div class="account-container-wrapper d-flex pl-0 mt-2 py-3" style="height: 150vh;">
      <div class="col-12 py-2 px-0">
        <div class="col-12 pt-2 d-flex">
          <div class="col-8">
            <span>
              <span class="text-sm font-weight-500">{{$t('commission.current_commission')}}</span> - <span style="color: #FEE19F" class="font-weight-600">{{Number(commissionInfo.commission).toLocaleString()}}</span>
            </span>
          </div>
          <div class="col-4">
            <!-- <button class="btn btn-customize text-nowrap" style="float: right;" @click="transferToWallet">{{$t('commission.transfer_commission')}}</button> -->
          </div>
        </div>
        <div class="col-12 d-flex mt-4">
          <div class="col-6 text-center pb-4 d-flex" style="border-right: 1px solid #ffe8b7">
            <div class="mr-3">
              <img src="https://kyat7.com/static/img/friends.07026e39.png" style="width: 3rem;" alt="">
            </div>
            <div>
              <span class="text-sm font-weight-600 text-nowrap" style="color: #D4D4EA">{{$t('commission.total_member')}}</span>
              <br>
              <span class="font-weight-600 text-nowrap text-lg" style="color: #FEE19F">{{commissionInfo.total_member}}</span>
            </div>
          </div>
          <div class="col-6 text-center pb-4 d-flex">
            <div class="mr-3">
              <img src="https://kyat7.com/static/img/all_in.5455f4b9.png" style="width: 3rem;" alt="">
            </div>
            <div>
              <span class="text-sm font-weight-600 text-nowrap" style="color: #D4D4EA">{{ $t('commission.total_commission') }}</span><br>
              <span class="font-weight-600 text-nowrap text-lg" style="color: #FEE19F">{{Number(commissionInfo.total_commission).toLocaleString()}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="col-6 text-center pt-4 d-flex" style="border-top: 1px solid #ffe8b7; border-right: 1px solid #ffe8b7">
            <div class="mr-3">
              <img src="https://kyat7.com/static/img/really_friends.65386c34.png" style="width: 3rem;" alt="">
            </div>
            <div>
              <span class="text-sm font-weight-600 text-nowrap" style="color: #D4D4EA">{{$t('commission.today_member')}}</span><br>
              <span class="font-weight-600 text-nowrap text-lg" style="color: #FEE19F">{{commissionInfo.today_new_member}}</span>
            </div>
          </div>
          <div class="col-6 text-center pt-4 d-flex" style="border-top: 1px solid #ffe8b7">
            <div class="mr-3">
              <img src="https://kyat7.com/static/img/last_in.c5ab42d9.png" style="width: 3rem;" alt="">
            </div>
            <div>
              <span class="text-sm font-weight-600 text-nowrap" style="color: #D4D4EA">{{$t('commission.today_commission')}}</span><br>
              <span class="font-weight-600 text-nowrap text-lg" style="color: #FEE19F">{{Number(commissionInfo.today_commission).toLocaleString()}}</span>
            </div>
          </div>
        </div>

        <!-- <div class="d-flex mt-3">
          <div class="col-6 pt-3">
            <span>
              <span class="text-sm font-weight-500">{{$t('commission.today_invite_bonus')}}</span> - <span style="color: #FEE19F" class="font-weight-600">{{Number(commissionInfo.commission).toLocaleString()}}</span>
            </span>
          </div>
          <div class="col-6 pt-3">
            <span>
              <span class="text-sm font-weight-500">{{$t('commission.total_invite_bonus')}}</span> - <span style="color: #FEE19F" class="font-weight-600">{{Number(commissionInfo.commission).toLocaleString()}}</span>
            </span>
          </div>
        </div> -->
      </div>
    </div>
    <nav class="side-nav my-3 py-2" style="border-radius: 10px; background: #552A07;">
      <div class="text-center py-2 pt-3">
        <h3 class="text-uppercase" style="color: #D4D4EA">{{$t('commission.invitation_link')}}</h3>
        <h3 class="text-white my-4" id="textToCopy">{{commissionInfo.link}}</h3>
        <button class="btn btn-customize" @click="copyText">{{$t('commission.copy_and_share')}}</button>
      </div>
    </nav>
    <nav class="side-nav mt-0 py-2" style="border-radius: 10px; background: #552A07;">
      <div class="text-center py-2 pt-3 d-flex flex-column">
        <h3 class="text-uppercase" style="color: #D4D4EA">{{$t('commission.invitation_qr_code')}}</h3>
          <img class="my-4 mx-auto" id="imageToDownload" style="width: 15rem; " :src="`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${commissionInfo.link}`" alt="">
        <div>
          <button class="btn btn-customize" @click="captureThisVoucher()">{{$t('commission.download_qr_code')}}</button>
        </div>
       
      </div>
    </nav>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { Dialog, Toast } from 'vant';

export default {
  data() {
    return {
      dynamicLink: 'https://luckywin17888.com/auth/register?code=ABCDEF',
      commissionInfo: {},
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    async captureThisVoucher() {
        Dialog.confirm({
            title: this.$t('commission.warning_message'),
            cancelButtonText: this.$t('commission.cancel'),
            confirmButtonText: this.$t('commission.confirm'),
        }).then(async () => {
                await this.captureElement();
            Toast.success(this.$t('commission.done'));
        }).catch(() => {});
        
    },
    async captureElement() {
      try {
        const imgSrc = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${this.dynamicLink}`;

        // Fetch the image data
        const response = await fetch(imgSrc);
        const blob = await response.blob();

        // Create a new link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob); // Create a URL for the blob
        link.download = 'downloaded-image.png'; // Specify the filename

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    },
    transferToWallet() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to transfer commission to main wallet?'
            : 'ကော်ငွေများကို ပိုက်ဆံအိတ်သို့လွဲရန် သေချာပါသလား။',
        cancelButtonText: this.$t('commission.cancel'),
        confirmButtonText: this.$t('commission.confirm'),
      })
        .then(async () => {
          const res = await axios.post('/transferComToUserAmount', 
          {
              headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          });
          await this.commissionInfoFetching()
          await this.fetchUser();
          
          Toast.success(this.$t('commission.done'));
        })
        .catch(() => {});
    },
    copyText() {
      // Get the text to copy
      const textToCopy = document.getElementById('textToCopy').innerText;

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();

      // Execute the 'copy' command
      document.execCommand('copy');

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      alert('You have copy Link');
    },
    async commissionInfoFetching() {
      const res = await axios.get('user/commission-info',{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
      this.commissionInfo = res.data.data;
    },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    const authUser = await this.fetchUser();
    await this.commissionInfoFetching()
    // this.$store.commit('setLoading', false);
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if(authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
  },
};
</script>
<style scoped>
.btn-customize {
  background: linear-gradient(180deg, rgb(255,242,135) 0%, rgb(157,106,41) 100%);
  color: black !important;
  padding: 0.5rem;
}

.side-nav .btn-customize {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    font-family: NotoSansMyanmar;
    font-weight: 700 !important;
}
.error {
  margin-bottom: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  font-weight: bold;
}
.account-container-wrapper {
    width: auto;
    /* height: 200px; */
    border-radius: 10px;
    color: #FFFCFC;
    background: #552A07;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
</style>
