<template>
    <div style="background-image: url('https://img.freepik.com/premium-photo/scene-showing-black-red-wallpaper_869640-735194.jpg?size=626&ext=jpg&ga=GA1.1.331063233.1723471334&semt=ais_hybrid'); min-height: 100vh; height: auto; background-size: cover;" class="pt-5">
            <div class="col-11 mx-auto card border-0 mb-0 auth-card mt-5" style="background: rgba(255, 255, 255, 0.25);">
                <img
                    class="mx-auto my-3"
                    src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/hulk333/photo_2024-11-28_6.37.59_PM-remo.png"
                    alt=""
                    @click="$router.push('/home')"
                    style="width: 20rem; height: auto"
                />
                
                <div class="card-body px-lg-7 py-lg-7">
                    <div class="text-center text-muted mb-4 text-white">
                      <h3 _ngcontent-dpt-c65="" class="text-white text-center text-sm">OTP ကုဒ်ကို SMS မှတစ်ဆင့်  သင့်ဖုန်းနံပါတ် <br><span class="text-danger text-lg">{{ $store.state.authUser.phone }}</span> သို့ ပေးပို့ပြီးပါပြီ။</h3>


                        <div class="error text-white d-flex" v-if="error">
                            <span class="mx-auto">
                                {{ error }}
                            </span>
                        </div>
                    </div>
                    <form role="form">
                        <small class="text-danger">{{
                            errors.code
                        }}</small>
                        <div class="form-group">
                            <van-password-input
                                :value="code"
                                :mask="false"
                                :focused="showKeyboard"
                                @focus="showKeyboard = true"
                                theme="dark"
                            />
                            <van-number-keyboard
                                v-model="code"
                                :maxlength="6"
                                :show="showKeyboard"
                                @blur="showKeyboard = false"
                                theme="dark"
                            />
                        </div>
                        <div
                            class="
                                text-center
                                mt-3
                                d-flex
                                justify-content-between
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-success my-4"
                                style="color: white !important;"
                                @click="verifyCode"
                            >
                                <div v-if="!isVerifying">
                                    {{
                                        $store.state.language === "en"
                                            ? "Confirm"
                                            : "အတည်ပြုမည်။"
                                    }}
                                </div>

                                <Loading
                                    color="#ffffff"
                                    v-if="isVerifying"
                                    size="24"
                                    class="px-4 py-0"
                                />
                            </button>
                            <van-count-down class="pt-2" v-if="$store.state.authUser.otp_resend_expired_at > 0" :time="$store.state.authUser.otp_resend_expired_at" @finish="finishCountdown">
                              <template #default="timeData">
                                <span class="block">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block">{{ timeData.seconds }}</span>
                              </template>
                            </van-count-down>

                            <button
                                type="button"
                                class="btn btn-dark my-4"
                                @click="resendCode"
                                v-if="$store.state.authUser.otp_resend_expired_at === 0"
                            >
                                <div v-if="!isResend">
                                    {{
                                        $store.state.language === "en"
                                            ? "Resend Code"
                                            : "Code ပြန်ပို့ပေးပါ။"
                                    }}
                                </div>
                                <Loading
                                    color="#ffffff"
                                    size="24"
                                    class="px-4 py-0"
                                    v-else
                                />
                            </button>
                        </div>
                        <!-- <div class="col-6">
                            <a @click="logout" class="new-account-text"
                                ><b>{{
                                    $store.state.language === "en"
                                        ? "Re-create account"
                                        : "အကောင့်ပြန်ဖွင့်မည်။"
                                }}</b></a
                            >
                        </div> -->
                    </form>
                </div>
            </div>
    </div>
</template>

<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      code: "",
      error: false,
      errors: {},
      isVerifying: false,
      isResend: false,
      showKeyboard: true,
    };
  },
  methods: {
    finishCountdown() {
      console.log('success');
      this.fetchUser();
    },
    ...mapActions(['fetchUser']),
    logout() {
      try {
        this.$auth.logout({
          redirect: '/auth/register',
        });
      } catch (error) {
        console.log(error);
      }
    },
    async verifyCode() {
      this.isVerifying = true;
      this.error = false;
      if (!this.code) {
        this.error = this.$store.state.language === 'en'
          ? 'Required Code'
          : 'Code လိုအပ်ပါသည်။';
        this.isVerifying = false;
        return false;
      }
      if (this.code && this.code.length < 6) {
        this.error = this.$store.state.language === 'en'
          ? 'Code must be 6 characters'
          : 'Code သည် 6 လုံးဖြစ်ရမည်။';
        this.isVerifying = false;
        return false;
      }
      try {
        const res = await axios.post('/auth/verify-code', {
          two_factor_code: this.code,
        }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.$router.push('/');
        this.error = false;
        this.isVerifying = false;
      } catch (err) {
        this.error = this.$store.state.language === "en" ? "Code Not Match" : "Code မမှန်ပါ။";
      }
      this.isVerifying = false;
      return true;
    },

    async resendCode() {
      this.isResend = true;
      try {
        const res = await axios.get('/auth/resend-code', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.fetchUser();
      } catch (err) {
        console.log(err);
      }
      this.isResend = false;
      return true;
    },
  },
  async mounted() {
    // const authUser = await this.fetchUser();
    // if (!authUser) {
    //   this.$router.push('/auth/login');
    // }
    if (authUser && authUser.is_verified) {
      this.$router.push('/home');
    }
  },
};
</script>

<style scoped>
/* Change placeholder text color for all input elements */
::placeholder {
            color: #2c2b2b; /* Light gray color */
        }

.showable-password {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1000;
}
.new-account-text {
    cursor: pointer;
}
.error {
    margin-bottom: 13px;
    margin-top: 13px;
    height: 30px;
    display: flex;
    align-items: center;
    color: white;
    border: 1px solid red;
    border-radius: 8px;
    padding: 0px 15px;
    background: #ff000014;
    font-weight: bold;
}
.auth-card {
    background: #f7fafc;
}



.colon {
    display: inline-block;
    margin: 0 4px;
    color: #1989fa;
  }
  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #1989fa;
    border-radius: 4px;
  }
</style>
